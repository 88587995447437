<template>
    <section class="blockElement allNotify" :class="[{'space':$route.name=='allNotifications'}]">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="flex-between mb-3 flex-wrap">
                        <h3 class="medium f-28 darkblueColor mb-0">{{$route.name=='allNotifications'?'Notifications':''}}</h3> 
                        <div class="d-flex align-items-center fromTodate">
                            <div class="form-group mb-0 me-3 d-flex align-items-center">
                                <label class="me-2 mb-0"> From</label>
                                <div class="position-relative">
                                    <input type="date" class="form-control" v-model="startDate" min="1970-01-01" :max="max1" required pattern="\d{4}-\d{2}-\d{2}" @change="chnageDate($event)" />
                                </div>
                            </div>
                            <div class="form-group mb-0 d-flex align-items-center">
                                <label class="me-2 mb-0"> To</label>
                                <div class="position-relative">
                                    <input type="date" class="form-control" v-model="endDate" min="1970-01-01" :max="max" required pattern="\d{4}-\d{2}-\d{2}" @change="chnageDate($event)" />
                                    <!--<p class="text-danger f-12">upper case</p>-->
                                </div>
                            </div>
                        </div>
                    </div>
                        <div class="dataTable table-responsive position-relative">
                             <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                            <table id="example" class="table currencyTable noWrap responsiveTable">
                                <thead>
                                    <tr class="border-0">
                                        <th>MESSAGE</th>
                                        <th class="text-end">RELATED TRADER TICKET</th>
                                    </tr>
                                </thead>
                                <tbody v-if="Object.keys(store.userNotificationList).length && store.userNotificationList.content && store.userNotificationList.content.length">
                                    <tr class="border-0 hover" v-for="item,index in store.userNotificationList.content" :key="index">
                                        <td data-label="Message">
                                            <p class="mb-2 f-13 line-4 neutralGrey">{{htmlToText(item.message)}}</p>
                                            <p class="mb-0 f-12">{{formatDate(item.dateTime)}}</p>
                                        </td>
                                        <td data-label="Related trader ticket"><a href="javascript:void(0)" class="text-end d-block mb-2 f-13 line-4 secondary">{{item.providerTicket || ''}}</a></td><!-- @click="selectedTicket = item.providerTicket;fullDetail(item.providerTicket)" -->
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="2"><Nodata></Nodata></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="tableFooter d-flex align-items-center justify-content-between flex-wrap" v-if="Object.keys(store.userNotificationList).length && store.userNotificationList.totalElements > limit && store.userNotificationList.content.length">
                            <div class="d-flex align-items-center">
                                <!-- <p class="f-14 mb-0 me-2">{{ ((page * limit) - limit) + 1 }} - {{limit * page}} of {{store.openPosition.length}} trades Show</p> -->
                                <select class="f-14 form-select border-less w-auto" v-model="limit" @change="callNotificationList()">
                                    <option v-if="store.userNotificationList.totalElements >= 10" value="10">10</option>
                                    <option v-if="store.userNotificationList.totalElements >= 20" value="20">20</option>
                                    <option v-if="store.userNotificationList.totalElements >= 50" value="50">50</option>
                                    <option v-if="store.userNotificationList.totalElements >= 100" value="100">100</option>
                                </select>
                                <p class="f-14 mb-0 ms-2">{{$route.name=='allNotifications'?'Notifications':'Logs'}}</p>
                            </div>
                            <Pagination :pageNo="page" :totalRecords="store.userNotificationList.totalElements" :perPage="limit" type="notification"></Pagination>
                        </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import moment from 'moment'
import Pagination from "@/components/shared/pagination.vue"
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data(){
        return {
            startDate : moment().subtract(1,'year').format('YYYY-MM-DD'),
            endDate : moment().add(1,'day').format('YYYY-MM-DD'),
            page: 1,
            limit: 10,
            max : moment().add(1,'day').format('YYYY-MM-DD'),
            max1 : moment().format('YYYY-MM-DD'),
            selectedTicket : -1
        };
    },
    components: {
        Pagination
    },
    methods:{
        htmlToText(text){
            if(text){
                // Create a new div element
                var tempDivElement = document.createElement("div");

                // Set the HTML content with the given value
                tempDivElement.innerHTML = text;

                // Retrieve the text property of the element 
                return tempDivElement.textContent || tempDivElement.innerText || "";
            }else{
                return ""
            }
        },
        fullDetail(ticketID){
            if(ticketID){
                // if(ticketID.includes('.')){
                //     ticketID = ticketID.split('.')[1];
                // }
                this.store.getFullDetailClosedTrade({}, true, ticketID, this, this.store.userSelectedAccount.brokerAccountId);
            }
        },
        chnageDate(e){
            if(e.target.value){
                this.callNotificationList()
            }
        },
        formatDate(date){
            if(date){
                let d = moment(date)
                let tz = (new Date().getTimezoneOffset());
                d.add(tz, 'minutes')
                return moment(d).format('DD MMM YYYY HH:mm a')
            }
        },
        callNotificationList(){
            let form = {
                'targetBrokerAccountId' : this.store.userSelectedAccount.brokerAccountId,
                'page' : parseInt(this.page)-1,
                'size' : this.limit,
                'sort' : 'id,desc',
                'dateTo' : moment(this.endDate).format('YYYY-MM-DD'),
                'dateFrom' : moment(this.startDate).format('YYYY-MM-DD')
            };
           this.store.userNotifications(form,false)
        }
    },
    mounted(){
        this.callNotificationList()
    }
}
</script>
<style>
    input[type="date"]::-webkit-clear-button {
    display: none;
}
</style>